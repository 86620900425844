import gql from 'graphql-tag';
import apolloClient from '../apollo';

/**
 * State
 */
const defaultClient = () => {
  return {
    id: '_new',
    number: '_new',
    name: '',
  };
};

const getDefaultState = () => {
  return {
    clients: [],
  };
};
const state = getDefaultState();

/**
 * Defines the fields which are requested for an client
 * used for all queries/mutations which return an client
 * @type {DocumentNode}
 */
const CLIENT_REQUEST_FIELDS = gql`
  fragment clientFields on Client {
    id
    number
    name
    street
    zip
    city
    description
    type
    smtpHost
    smtpUser
    smtpPort
    smtpPass
    mailFrom
    mailBccTo
    mailReplyTo
    emailSignature
    electricians {
      name
      id
      number
    }
    archiveState
  }
`;

/**
 * sanitize inputs and create payload to send to the graphql api
 * @param {*} project
 */
const createPayload = (client) => {
  /** Only send ID's for electricians */
  if (client.electricians) {
    const ids = [];
    client.electricians.map((electrician) => {
      ids.push(electrician.id);
    });
    client.electricians = ids;
  }
  /** Only send ID's for employers */
  if (client.employers) {
    const ids = [];
    client.employers.map((employer) => {
      ids.push(employer.id);
    });
    client.employers = ids;
  }

  const payload = {
    ...(client.name ? { name: client.name } : {}),
    ...(client.street ? { street: client.street } : {}),
    ...(client.zip !== null ? { zip: client.zip } : {}),
    ...(client.city !== null ? { city: client.city } : {}),
    ...(client.description !== null ? { description: client.description } : {}),
    ...(client.smtpHost !== null ? { smtpHost: client.smtpHost } : {}),
    ...(client.smtpUser !== null ? { smtpUser: client.smtpUser } : {}),
    ...(client.smtpPort !== null ? { smtpPort: client.smtpPort } : {}),
    ...(client.smtpPass !== null ? { smtpPass: client.smtpPass } : {}),
    ...(client.mailFrom !== null ? { mailFrom: client.mailFrom } : {}),
    ...(client.mailBccTo !== null ? { mailBccTo: client.mailBccTo } : {}),
    ...(client.mailReplyTo !== null ? { mailReplyTo: client.mailReplyTo } : {}),
    ...(client.emailSignature !== null ? { emailSignature: client.emailSignature } : {}),
    electricians: client.electricians,
    employers: client.employers,
    archiveState: client.archiveState,
  };
  return payload;
};

/**
 * Actions
 */
const actions = {
  async fetchClients({ commit, dispatch }, payload) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query {
            clients {
              ...clientFields
            }
          }
          ${CLIENT_REQUEST_FIELDS}
        `,
      });
      commit('SET_CLIENTS', response.data.clients);
    } catch (err) {
      throw new Error(err);
    }
  },

  async fetchClientByNumber({ commit, dispatch }, clientNumber) {
    try {
      const response = await apolloClient.query({
        query: gql`
          query clientByNumber($clientNumber: Int!) {
            clientByNumber(clientNumber: $clientNumber) {
              ...clientFields
            }
          }
          ${CLIENT_REQUEST_FIELDS}
        `,
        variables: {
          clientNumber: parseInt(clientNumber),
        },
      });
      commit('ADD_OR_UPDATE_CLIENT', response.data.clientByNumber);
    } catch (err) {
      throw new Error(err);
    }
  },

  async createClient({ commit, dispatch }, client) {
    const payload = createPayload(client);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation createClient($payload: ClientInput!) {
            createClient(clientInput: $payload) {
              ...clientFields
            }
          }
          ${CLIENT_REQUEST_FIELDS}
        `,
        variables: {
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber gespeichert!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_CLIENT', '_new'); // remove temporary _new client
      commit('ADD_OR_UPDATE_CLIENT', response.data.createClient);
      return response.data.createClient.number;
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim erstellen! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async updateClient({ commit, dispatch }, client) {
    const payload = createPayload(client);

    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation updateClient($clientNumber: Int!, $payload: ClientInput!) {
            updateClient(clientNumber: $clientNumber, clientInput: $payload) {
              ...clientFields
            }
          }
          ${CLIENT_REQUEST_FIELDS}
        `,
        variables: {
          clientNumber: client.number,
          payload: payload,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber aktualisiert!',
        type: 'success',
        lifetime: 2,
      });
      commit('ADD_OR_UPDATE_CLIENT', response.data.updateClient);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim bearbeiten! Bitte Eingabefelder überprüfen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  async deleteClient({ commit, dispatch }, clientNumber) {
    try {
      const response = await apolloClient.mutate({
        mutation: gql`
          mutation deleteClient($clientNumber: Int!) {
            deleteClient(clientNumber: $clientNumber)
          }
        `,
        variables: {
          clientNumber: clientNumber,
        },
      });
      dispatch('message', {
        message: 'Auftraggeber gelöscht!',
        type: 'success',
        lifetime: 2,
      });
      commit('REMOVE_CLIENT', clientNumber);
    } catch (err) {
      dispatch('message', {
        message: 'Fehler beim löschen!',
        type: 'danger',
        lifetime: 1,
      });
      throw new Error(err);
    }
  },

  /**
   * create a new client object in store and return it
   * @param {*} param0
   * @param {*} client
   */
  async initClient({ commit }) {
    commit('ADD_OR_UPDATE_CLIENT', defaultClient());
  },

  clientLocalUpdate({ commit }, client) {
    commit('ADD_OR_UPDATE_CLIENT', client);
  },

  clientCleanup({ commit }) {
    commit('REMOVE_CLIENT', '_new');
  },
};

/**
 * Mutations
 */
const mutations = {
  SET_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_CLIENT_DEFAULT_STATE: (state) => {
    Object.assign(state, getDefaultState());
  },
  SET_CLIENTS: (state, clients) => (clients ? (state.clients = clients) : (state.clients = [])),
  ADD_CLIENT: (state, client) => state.clients.push(client),
  ADD_OR_UPDATE_CLIENT: (state, updatedClient) => {
    const existingClient = state.clients.find((client) => client.id === updatedClient.id);
    if (existingClient) {
      Object.assign(existingClient, updatedClient);
    } else {
      state.clients.push(updatedClient);
    }
  },
  REMOVE_CLIENT: (state, clientId) => {
    const index = state.clients.findIndex((client) => client.id === clientId);
    state.clients.splice(index, 1);
  },
};

/**
 * Getters
 */
const getters = {
  getClients: (state) => state.clients,
  getClient: (state) => (clientId) => state.clients.find((client) => client.id == clientId),
  getClientByNumber: (state) => (clientNumber) =>
    state.clients.find((client) => client.number == clientNumber),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
